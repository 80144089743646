import React, {
  forwardRef,
  InputHTMLAttributes,
  Ref,
  useCallback,
  useState,
} from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'

import { addAlphaToColor } from 'common/utils/style'

import { Button } from './Button'
import { StyledLabel } from './Field'
import { Icon } from './Icon'
import { Text } from './Text'

export type UploadFileTypes =
  | '.jpg'
  | '.pdf'
  | '.doc'
  | '.png'
  | '.gif'
  | '.xlsx'

type UploadProps = {
  name?: string
  label?: string
  placeholder?: string
  acceptedFileTypes?: UploadFileTypes[]
  setFilesCallback?: (files: File[]) => void
  value: File[]
} & InputHTMLAttributes<HTMLInputElement>

export const Upload = forwardRef(function Upload(
  {
    name,
    label,
    placeholder,
    acceptedFileTypes,
    setFilesCallback,
    value: _,
    ...props
  }: UploadProps,
  ref: Ref<HTMLInputElement>
): JSX.Element {
  const [files, setFiles] = useState<File[]>([])

  const handleSetFiles = useCallback(
    (newFiles: File[]) => {
      setFiles(newFiles)
      setFilesCallback?.(newFiles)
    },
    [setFilesCallback]
  )

  const handleDelete = useCallback(
    (fileIndex: number) => {
      const newFiles = [...files]
      newFiles.splice(fileIndex, 1)
      handleSetFiles(newFiles)
    },
    [files, handleSetFiles]
  )

  const onDrop = useCallback(
    // TODO: fix types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acceptedFiles: any) => {
      const newFiles = [...files, ...acceptedFiles]
      handleSetFiles(newFiles)
    },
    [files, handleSetFiles]
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // TODO: fix accepted file types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    accept: acceptedFileTypes?.join(', ') as any,
  })

  return (
    <StyledLabel as="div" variant="sixteen">
      {label}
      <UploadWrapper isDragActive={isDragActive} {...getRootProps()}>
        <input ref={ref} {...getInputProps({ name: name })} {...props} />
        <Text as="span" variant="sixteen">
          {placeholder || <Icon icon="download" />}
        </Text>
      </UploadWrapper>

      <ul>
        {files.map((file, index) => (
          <File key={`${file.name}-${index}`}>
            <div>
              <Text as="p" variant="sixteen">
                {file.name}
              </Text>
              <Text as="span" variant="fourteen" css={{ opacity: 0.6 }}>
                {(file.size / (1024 * 1024)).toFixed(2)} MB ·{' '}
                {file.name.substring(
                  file.name.lastIndexOf('.') + 1,
                  file.name.length
                ) || file.name}
              </Text>
            </div>
            <Button
              variant="text"
              leftIcon="close"
              onClick={() => handleDelete(index)}
            />
          </File>
        ))}
      </ul>
    </StyledLabel>
  )
})

const UploadWrapper = styled.div<{ isDragActive?: boolean }>`
  border: 1px
    ${({ theme, isDragActive }) =>
      `${isDragActive ? 'solid' : 'dashed'} ${addAlphaToColor(
        theme.colors.foreground.default,
        40
      )}`};
  border-radius: 0.5rem;

  cursor: pointer;

  padding: 2rem 1rem;
  margin-top: 0.5rem;
  text-align: center;

  ${Text} {
    color: ${({ theme }) =>
      addAlphaToColor(theme.colors.foreground.default, 60)};
  }
`

const File = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${({ theme }) =>
    addAlphaToColor(theme.colors.foreground.default, 5)};
  border-radius: 0.5rem;

  padding: 0.5rem 0rem 0.5rem 0.75rem;
  margin-top: 0.5rem;
`
