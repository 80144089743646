import { useRouter } from 'next/router'
import { Key, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Icon, Text } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { DynamicFormStepsStoryblok, FormStepStoryblok } from 'common/types'
import {
  FormField,
  getEmailText,
  getFieldComponent,
  parseWithFormValues,
  sendEmail,
} from 'common/utils/forms'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import { useTranslation } from 'lib/i18n'

import { Editable } from '../Editable'
import * as S from './DynamicForm.style'
import { DynamicFormHeader } from './DynamicFormHeader'

type DynamicFormStepsProps = {
  block: DynamicFormStepsStoryblok
}

export const DynamicFormSteps = ({
  block,
  ...props
}: DynamicFormStepsProps): JSX.Element => {
  const { email_address, submit_label, success_message, steps } = block
  const { i18n } = useTranslation()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    trigger,
  } = useForm()

  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const [activeStep, setActiveStep] = useState(0)
  const isFirstStep = activeStep === 0
  const isLastStep = activeStep === steps.length - 1

  const [loading, setLoading] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [messageError, setMessageError] = useState(false)

  const asPath = router.asPath.substring(1)
  const url = `${process.env.NEXT_PUBLIC_ORIGIN}${asPath}`

  const fields = useMemo(() => {
    return steps.reduce<FormStepStoryblok['fields']>((acc, step) => {
      return [...acc, ...step.fields]
    }, [])
  }, [steps])

  const onPrevious = () => {
    setActiveStep(activeStep - 1)
  }

  const onNext = async () => {
    const names = steps[activeStep].fields.map((field) => field.name || '')
    const validate = await trigger(names)

    if (validate) {
      setActiveStep(activeStep + 1)
    }
  }

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    setMessageError(false)

    const to = parseWithFormValues(email_address, data).replace(
      /\[(.*?)\]/g,
      ''
    )

    try {
      const recipients = to.split(';')
      const message = await getEmailText(data, fields, {
        recipients: recipients.join(', '),
        url,
      })

      if (!recipients || !message || !url) {
        throw new Error(
          'Missing one or more of the required fields: recipients, message, url'
        )
      }

      const customerName = getValues('name') || ''
      const customerEmail = getValues('email') || getValues('Email')

      const response = await sendEmail({
        to: recipients,
        message,
        formURL: url,
        customer: {
          name: customerName,
          email: customerEmail,
        },
      })

      if (!response.ok) {
        throw new Error('Error sending email')
      }

      trackEvent(GTMEvents.FormSubmission, {
        'tfp-email-address': to,
      })
      setMessageSent(true)
    } catch {
      setMessageError(true)
    } finally {
      setLoading(false)
    }
  })

  const resetForm = () => {
    setLoading(false)
    setMessageError(false)
    setMessageSent(false)
  }

  return (
    <S.Section
      as="section"
      variant={isDrawer ? 'full' : 'extra-narrow'}
      {...props}
    >
      <S.Wrapper isDrawer={isDrawer}>
        {block.title && <DynamicFormHeader content={block} />}

        {!messageSent ? (
          <S.Form onSubmit={onSubmit}>
            {steps[activeStep]?.title && (
              <Text as="h3" variant="eighteen">
                {activeStep + 1} — {steps[activeStep].title}
              </Text>
            )}
            {steps[activeStep]?.fields?.map(
              (field: FormField, idx: Key | null | undefined) => (
                <Editable block={field} key={idx}>
                  {getFieldComponent({
                    field,
                    getValues,
                    errors,
                    register,
                    control,
                  })}
                </Editable>
              )
            )}

            <S.ButtonsWrapper>
              <Button
                disabled={loading || isFirstStep}
                variant="ghost"
                css={{ marginTop: '1.25rem' }}
                onClick={onPrevious}
              >
                {i18n('previous')}
              </Button>
              {isLastStep ? (
                <Button
                  disabled={loading || !isLastStep}
                  isLoading={loading}
                  variant="solid"
                  type="submit"
                  css={{ marginTop: '1.25rem' }}
                >
                  {submit_label}
                </Button>
              ) : (
                <Button
                  disabled={loading || isLastStep}
                  variant="solid"
                  css={{ marginTop: '1.25rem' }}
                  onClick={onNext}
                >
                  {i18n('next')}
                </Button>
              )}
            </S.ButtonsWrapper>

            {messageError && (
              <S.ErrorText
                as="span"
                variant="fourteen"
                css={{ marginTop: '1rem' }}
              >
                {i18n('contact.error.message')}
              </S.ErrorText>
            )}
          </S.Form>
        ) : (
          <>
            <S.SentBox>
              <Icon icon="checkmark" styles={{ flexShrink: 0 }} />
              <Text as="span" variant="sixteen" css={{ marginLeft: '0.75rem' }}>
                {parseWithFormValues(success_message, getValues()) ||
                  i18n('contact.messageSent')}
              </Text>
            </S.SentBox>
            <Button
              variant="ghost"
              onClick={resetForm}
              css={{ marginTop: '2rem' }}
            >
              {i18n('contact.writeAnotherMessage')}
            </Button>
          </>
        )}
      </S.Wrapper>
    </S.Section>
  )
}
