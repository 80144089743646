import { useRouter } from 'next/router'
import { Key, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Icon, Text } from 'common/UI'
import useDrawer from 'common/hooks/useDrawer'
import { DynamicFormStoryblok } from 'common/types'
import {
  FormField,
  getEmailText,
  getFieldComponent,
  parseWithFormValues,
  sendEmail,
} from 'common/utils/forms'
import { GTMEvents, trackEvent } from 'common/utils/gtm'
import { useTranslation } from 'lib/i18n'
import { DynamicFormHeader } from 'modules/Blocks/DynamicForms/DynamicFormHeader'

import { Editable } from '../Editable'
import * as S from './DynamicForm.style'

type DynamicFormProps = {
  block: DynamicFormStoryblok
}

export const DynamicForm = ({
  block,
  ...props
}: DynamicFormProps): JSX.Element => {
  const { i18n } = useTranslation()
  const router = useRouter()
  const {
    register,
    handleSubmit,
    control,
    getValues,
    formState: { errors },
  } = useForm()

  const { drawer } = useDrawer()
  const isDrawer = !!drawer

  const [loading, setLoading] = useState(false)
  const [messageSent, setMessageSent] = useState(false)
  const [messageError, setMessageError] = useState(false)
  const {
    email_address,
    fields,
    submit_label,
    success_message,
    email_subject,
  } = block

  const asPath = router.asPath.substring(1)
  const url = `${process.env.NEXT_PUBLIC_ORIGIN}${asPath}`

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true)
    setMessageError(false)

    const to = parseWithFormValues(email_address, data).replace(
      /\[(.*?)\]/g,
      ''
    )

    const subject = parseWithFormValues(email_subject, data).replace(
      /\[(.*?)\]/g,
      ''
    )

    try {
      const recipients = to.split(';')
      const message = await getEmailText(data, fields, {
        recipients: recipients.join(', '),
        url,
      })

      if (!recipients || !message || !url) {
        throw new Error(
          'Missing one or more of the required fields: recipients, message, url'
        )
      }

      const customerName = getValues('name')
      const customerEmail = getValues('email') || getValues('Email')

      const response = await sendEmail({
        to: recipients,
        subject,
        message,
        formURL: url,
        customer: {
          name: customerName || '',
          email: customerEmail,
        },
      })

      if (!response.ok) {
        throw new Error('Error sending email')
      }

      trackEvent(GTMEvents.FormSubmission, {
        'tfp-email-address': to,
      })
      setMessageSent(true)
    } catch {
      setMessageError(true)
    } finally {
      setLoading(false)
    }
  })

  const resetForm = () => {
    setLoading(false)
    setMessageError(false)
    setMessageSent(false)
  }

  return (
    <S.Section
      as="section"
      variant={isDrawer ? 'full' : 'extra-narrow'}
      {...props}
    >
      <S.Wrapper isDrawer={isDrawer}>
        {block.title && <DynamicFormHeader content={block} />}

        {!messageSent ? (
          <S.Form onSubmit={onSubmit}>
            {fields?.map((field: FormField, idx: Key | null | undefined) => (
              <Editable block={field} key={idx}>
                {getFieldComponent({
                  field,
                  getValues,
                  errors,
                  register,
                  control,
                })}
              </Editable>
            ))}

            <Button
              disabled={loading}
              isLoading={loading}
              variant="solid"
              type="submit"
              css={{
                marginTop: '1.25rem',
                width: isDrawer ? '100%' : 'fit-content',
              }}
            >
              {typeof submit_label === 'string'
                ? submit_label
                : submit_label.content?.map(
                    (content) => content?.content?.[0].text
                  )}
            </Button>

            {messageError && (
              <S.ErrorText
                as="span"
                variant="fourteen"
                css={{ marginTop: '1rem' }}
              >
                {i18n('contact.error.message')}
              </S.ErrorText>
            )}
          </S.Form>
        ) : (
          <>
            <S.SentBox>
              <Icon icon="checkmark" styles={{ flexShrink: 0 }} />
              <Text as="span" variant="sixteen" css={{ marginLeft: '0.75rem' }}>
                {parseWithFormValues(success_message, getValues()) ||
                  i18n('contact.messageSent')}
              </Text>
            </S.SentBox>
            <Button
              variant="ghost"
              onClick={resetForm}
              css={{ marginTop: '2rem' }}
            >
              {i18n('contact.writeAnotherMessage')}
            </Button>
          </>
        )}
      </S.Wrapper>
    </S.Section>
  )
}
